import React from 'react';
import './Home.css'
import profilePic from '../../assets/profile-pic-smile.jpg';
import video01 from '../../assets/SpotifyAppVideo.mp4';
import video02 from '../../assets/SpaceInvaderVideo.mp4';
import video03 from '../../assets/GoalGetterVideo.mp4';
import linkedIn from "../../assets/In-Blue-26.png";
import buzzySweets from "../../assets/BuzzySweetsHomePage02.JPG";

function Home() {


    return (
        <div class="container">
            <header><img src={profilePic} alt="Profile Image" width="100" height="100" />
                <h1 class="name">Paul Rose</h1>
                <p class="title">Web Developer</p>
                    <nav>
                        <a href="#home">Home</a>
                        <a href="#portfolio">Portfolio</a>
                        <a href="#about">About</a>
                        <a href="#contact">Contact</a>
                    </nav>
            </header>
            
            <section id="about">
                <h2>About Me</h2>
                I am eager to contribute my skills and creativity to innovative projects. With a strong foundation in HTML, CSS, JavaScript, and React, I thrive in turning concepts into user-friendly and visually appealing websites. I am excited about the opportunity to leverage my skills and contribute to impactful projects.
            </section>
            
            <section id="portfolio">
                <h2>Portfolio</h2>
                    <div class="tile-section">
                        <div class="project">
                                <div class="project-tile">
                                <h3>Buzzy Sweets Website</h3>
                                <p>Website that sells baked goods</p>
                                <img src={buzzySweets} alt="Profile Image" width="500" height="440" />
                                <p class="tech-stack">
                                    <strong>Tech Stack:</strong> HTML, CSS, React, Node.js, Firebase, Google Cloud Run, Square API
                                </p>
                                <a className="view-button buzzySweets" href="https://buzzysweets.com">View Site</a>
                            </div>
                        </div>

                        <div class="project">
                                <div class="project-tile">
                                <h3>Goal Getter App</h3>
                                <p>Goal Getter App helps set and achieve goals</p>
                                <video controls="controls" width="320" height="240"><source src={video03} type="video/mp4" />Your browser does not support the video tag.</video>
                                <p class="tech-stack">
                                    <strong>Tech Stack:</strong> HTML, CSS, React, Node.js, Express, MySQL
                                </p>
                                <a href="https://github.com/ptrose78/GoalsApp">View Project</a>
                            </div>
                        </div>
                        <div class="project">
                            <div class="project-tile">
                                <h3>Playlist app</h3>
                                <p>Playlist app that requests data from Spotify API</p>
                                <video controls="controls" width="320" height="240"><source src={video01} type="video/mp4" />Your browser does not support the video tag.</video>    
                                <p class="tech-stack">
                                    <strong>Tech Stack:</strong> HTML, CSS, React, Node.js
                                </p>
                                <a href="https://github.com/ptrose78/SpotifyApp">View Project</a>
                            </div>
                        </div>

                        <div class="project">
                            <div class="project-tile">
                                <h3>Video Game</h3>
                                <p>Video game inspired by Space Invaders</p>
                                <video controls="controls" width="320" height="240"><source src={video02} type="video/mp4" />Your browser does not support the video tag.</video>
                                <p class="tech-stack">
                                    <strong>Tech Stack:</strong> HTML, CSS, React, Node.js
                                </p>
                                <a href="https://github.com/ptrose78/SpaceInvaderGame">View Project</a>
                            </div>
                        </div>
                    </div>
            </section>
            
            <footer id="contact">
                <h2 class="contact-header">Contact</h2>
                <p class="email">ptrose78@gmail.com</p>

                <div class="social-icons-container">
                <a href="https://www.linkedin.com/in/paul-rose-a50b1b24a/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedIn} alt="LinkedIn Profile" />
                </a>
                </div>
            <p class="closer">Copyright © 2024 Paul Rose. All Rights Reserved.</p>
            </footer>
        </div>
    )
}

export default Home;